import {Fragment, MouseEvent, useEffect, useState} from "react";
import toast from "react-hot-toast";

import PageLayout from "../../../components/layouts/PageLayout";
import DocumentExpiryGrid from "./misc/DocumentExpiryGrid";
import DocumentExpiryList from "./misc/DocumentExpiryList";
import ViewSwitcher from "../../../components/layouts/ViewSwitcher";
import {verifyTimestamp} from "../../../utils/helpers/logicHelper";
import {MenuType} from "../../../utils/helpers/constants";
import {DocumentRenewals} from "../../../mocks/DocumentRenewals";
import {useGetExpiringDocumentsQuery, useSendMessageMutation} from "../../../store/slices/backoffice.slice";

const DocumentExpiry = () => {
  const {data: apiData, isLoading, isSuccess} = useGetExpiringDocumentsQuery('')
  const [createMessagingMutation, {isLoading: loadingMessage}] = useSendMessageMutation();
  const [view, setView] = useState<string>('GRID')
  const [actionIndex, setActionIndex] = useState<number>(0)
  const [documents, setDocuments] = useState<any>(apiData)
  const [activeNav, setActiveNav] = useState<string>('expiredDriversDocs')
  const searchValue = (value: any): void => setDocuments(value)

  useEffect(() => {
    if (isSuccess) setDocuments(apiData)
  }, [isSuccess]);

  const switchActiveNav = (event: MouseEvent, selected: string): void => {
    event.preventDefault()
    if (activeNav === selected) setActiveNav('')
    else setActiveNav(selected)
  }

  const getVehicleDocs = (document: any, docType: string): string => {
    let documents: string = "", date: Date = new Date();
    if (docType === 'registration_number' && verifyTimestamp(document.extras.insurance_document_expiry_date ?? '') < date) {
      documents += `insurance document, `
    }
    if (docType === 'registration_number' && verifyTimestamp(document.extras.hackney_permit_document_expiry_date ?? '') < date) {
      documents += `hackney permit, `
    }
    if (docType === 'registration_number' && verifyTimestamp(document.extras.road_worthiness_document_expiry_date ?? '') < date) {
      documents += `road worthiness, `
    }
    return documents
  }

  const sendNotification = async (profile: IDriverPartner, docType: string, badgeText: string, key: number): Promise<void> => {
    try {
      setActionIndex(key);
      const type: "APP" = "APP", from: string = "CUSTOM", to: string = profile.email;
      let subject: string = 'Document Expiry Notification';
      let docPrefix: string = docType === 'license_number' ? 'driver license' : `vehicle`
      let contentPrefix: string = `Notifying you of your document expiry information for your `
      let contentSuffix: string = badgeText === 'EXPIRED' ? 'which has expired.' : `which would expire this month.`
      let content: string = `${contentPrefix}${docPrefix} ${profile.extras[docType] ?? ''}, ${docType === 'registration_number' ? getVehicleDocs(profile.extras, docType) : ''}${contentSuffix}`;
      let filter: string = 'single', format: string = "ANNOUNCEMENT";
      let body: IMessagingRequest = {from, to, type, subject, content, format}
      await createMessagingMutation({filter, body}).unwrap();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const navSearch = (params: string): void => {
    if (isSuccess) {
      let expiredDriversDocs = apiData.data['expiredDriversDocs'].filter((forEach: any): boolean => JSON.stringify(forEach).toLowerCase().indexOf(params.toLowerCase()) !== -1);
      let expiringDriversDocs = apiData.data['expiringDriversDocs'].filter((forEach: any): boolean => JSON.stringify(forEach).toLowerCase().indexOf(params.toLowerCase()) !== -1);
      let expiredVehicleDocs = apiData.data['expiredVehicleDocs'].filter((forEach: any): boolean => JSON.stringify(forEach).toLowerCase().indexOf(params.toLowerCase()) !== -1);
      let expiringVehicleDocs = apiData.data['expiringVehicleDocs'].filter((forEach: any): boolean => JSON.stringify(forEach).toLowerCase().indexOf(params.toLowerCase()) !== -1);
      searchValue({data: {expiredDriversDocs, expiringDriversDocs, expiredVehicleDocs, expiringVehicleDocs}})
    }
  }

  return (
    <PageLayout active={MenuType.SUPPLY} title="Supply Service - Expiring Documents" loading={isLoading}
                navSearch={navSearch} searchType="DOCUMENT">
      {isSuccess &&
        <Fragment>
          <div className="container-fluid">
            <ViewSwitcher updateView={(value: 'GRID' | 'LIST') => setView(value)}/>
            {DocumentRenewals.map((item: IDocumentType, index: number) =>
              <Fragment key={index}>
                <div className="row align-items-center pointer-event"
                     onClick={(event: MouseEvent<HTMLDivElement>) => switchActiveNav(event, item.value)}>
                  <div className="col-md-6 mb-3">
                    <h5 className="card-title">
                      {item.name}
                      <span className="text-muted fw-normal ms-2">({documents?.data[item.value].length})</span>
                    </h5>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                      <div className="btn btn-link text-muted py-1 font-size-16">
                        {activeNav === item.value ? <i className="bx bx-caret-down"></i> :
                          <i className="bx bx-caret-up"></i>}
                      </div>
                    </div>
                  </div>
                </div>
                {view === 'GRID' ?
                  <div className="row mt-2">
                    {activeNav === item.value && documents?.data[item.value].map((driverItem: IDriverPartner, index: number) => (
                      <div key={index} className="col-md-3 col-sm-6">
                        <DocumentExpiryGrid gridIndex={index} document={driverItem} type={item.value}
                                            loading={loadingMessage}
                                            actionIndex={actionIndex} sendNotification={sendNotification}/>
                      </div>
                    ))}
                  </div> :
                  <div className="row mt-2">
                    {activeNav === item.value &&
                      <div className="col-lg-12">
                        <div className="card card-body">
                          <div className="table-responsive">
                            <table className="table table-nowrap align-middle">
                              <thead className="table-light">
                              <tr>
                                <th scope="col">Partner</th>
                                <th scope="col">License ID</th>
                                <th scope="col">Documents</th>
                                <th scope="col">Expiry Date</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {documents?.data[item.value].map((driverItem: IDriverPartner, index: number) => (
                                <DocumentExpiryList document={driverItem} type={item.value} listIndex={index}
                                                    actionIndex={actionIndex} loading={loadingMessage}
                                                    sendNotification={sendNotification}/>
                              ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </Fragment>
            )}
          </div>
        </Fragment>
      }
    </PageLayout>
  )
}

export default DocumentExpiry;
