import {useState} from "react";
import PageLayout from "../../../components/layouts/PageLayout";
import ComingSoon from "../../../components/modules/ComingSoon";
import {MenuType} from "../../../utils/helpers/constants";


const Home = () => {
  const [filterRender, setFilterRender] = useState<boolean>(false)

  return (
    <PageLayout active={MenuType.HOME} title="Hi, Welcome Back!" searchType="ANY">
      <div className="container-fluid">
        <div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
          <button className="btn btn-primary pointer-event" onClick={() => setFilterRender(true)}>
            <i className="bx bx-filter me-1"></i> Filter
          </button>
        </div>
      </div>
      <ComingSoon pageName={MenuType.HOME}/>
    </PageLayout>
  )
}

export default Home;
